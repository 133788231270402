import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useRef } from 'react'



const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ProductDesignHouseholdDustbins = props => {

  const outcomeDiv = useRef(null);

  const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  return (
  <Layout>
    <Helmet>
      <title>Generic - Forty by HTML5 UP</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.hddBanner.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <h1>blinky, clyde, inky, pinky.</h1>
            <h3>
              A family of dustbins that allows clean & efficient removal of
              waste. At the sametime it has an aesthetic appeal that can be
              customised to the user's preferences creating an emotional
              attachment which allows it to be shown-off as a Home decor &
              Furnishing product.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>the challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Dustbins are always regarded as something of an eyesore
                  because they are dirty and smelly. It is also hard to remove
                  trash in an easy and clean way and also you can see waste that
                  is already in the bin – an unpleasant sight. Thus, the aim was
                  to use an efficient and effective method of waste disposal
                  while at the same time changing the image of a dustbin from
                  something that is hidden to something that is shown off as a
                  Home decor & Furnishing product.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2015 - 2016
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 3-4 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Household product design
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> User behaviour observation &
                  interviews, conceptualisation, sketching of ideas, 3D
                  modelling & technical drafting and final 3D rendering of the
                  product.
                </span>
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.research.childImageSharp.fluid}
                alt="Blink Clyde Inky Pinky Research"
                title="Blink Clyde Inky Pinky Research"
              />
            </div>

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>
            
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>the approach</h2>
            <p className="full-text">
              Observational study was carried to find out how people interact
              and behave when they use a bin. Next involved finding an
              appropriate inspiration and metaphor that is commonly recognized
              and can be related by people so as to change the perception people
              have about dustbins at home.
            </p>

            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.inspiration.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Inspiration"
                  title="Blink Clyde Inky Pinky Inspiration"
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.sketch.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Sketch"
                  title="Blink Clyde Inky Pinky Sketch"
                />
              </div>
            </div>

            <p className="full-text">
              This research and inspiration phase lead to coming up with various
              different concepts and ideas. One of the ideas was further
              developed to be designed for the different senses.
            </p>

            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img
                  className="project-process-images"
                  fluid={props.data.components.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Inner Components"
                  title="Blink Clyde Inky Pinky Inner Components"
                />

                <Img
                  className="project-process-images"
                  fluid={props.data.disInspiration.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Dispensor Inspiration"
                  title="Blink Clyde Inky Pinky Dispensor Inspiration"
                />
                <Img
                  className="project-process-images"
                  fluid={props.data.howitworks.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky How it works"
                  title="Blink Clyde Inky Pinky How it works"
                />
                <Img
                  className="project-process-images"
                  fluid={props.data.dispenser1.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Dispensor Mechanism 1"
                  title="Blink Clyde Inky Pinky Dispensor Mechanism 1"
                />

                <Img
                  className="project-process-images"
                  fluid={props.data.dispenser2.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Dispensor Mechanism 2"
                  title="Blink Clyde Inky Pinky Dispensor Mechanism 2"
                />
                <Img
                  className="project-process-images"
                  fluid={props.data.oppurtunity.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Market Opportunity"
                  title="Blink Clyde Inky Pinky Market Opportunity"
                />
                <Img
                  className="project-process-images"
                  fluid={props.data.usage.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Flexible Usage"
                  title="Blink Clyde Inky Pinky Flexible Usage"
                />

                <Img
                  className="project-process-images"
                  fluid={props.data.senses.childImageSharp.fluid}
                  alt="Blink Clyde Inky Pinky Design for Senses"
                  title="Blink Clyde Inky Pinky Design for Senses"
                />
              </Slider>
              {/* <a href="/" className="show-more" id="load-more-project-images">
                <span className="arrow-show-more" />
                <p>SHOW MORE</p>
              </a> */}
            </div>
          </section>

          <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>the outcome</h2>
              <h3>
                Blinky, Clyde, Inky, Pinky are a family of dustbins, which
                people will treat it like a ‘being’ and show it off in the
                house.
              </h3>

              <div className="outcome">
                <div className="outcome-left">
                  <p>
                    With the help of an in-built bin liner roll-dispenser the
                    user does not have to touch the bin, thus ensuring clean and
                    efficient removal of waste. The presence of an inner lid
                    prevents the smell from spreading around when the bin is
                    being used.
                  </p>
                </div>
                <div className="outcome-right">
                  <p>
                    It also prevents the user from seeing the waste that is
                    already present in the bin. The external cover of the
                    dustbin is interchangeable thus allowing the user to
                    customize it to any colour or designs depending on the
                    user’s likes or the interiors of the room it is placed in.
                  </p>
                </div>
              </div>
            </div>
            <Img
              className="project-process-images"
              fluid={props.data.kitchen.childImageSharp.fluid}
              alt="Blink Clyde Inky Pinky Kitchen"
              title="Blink Clyde Inky Pinky Kitchen"
            />
            <Img
              className="project-process-images"
              fluid={props.data.outcome.childImageSharp.fluid}
              alt="Blink Clyde Inky Pinky Outcome"
              title="Blink Clyde Inky Pinky Inner Outcome"
            />
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/work"> my work</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/product-design-persono-coffee-table">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">persona.</div>
                <div className="text">
                  Coffee table for the living room at home that allows the
                  ultimate flexibility of usage for various contexts.
                </div>
                <div className="tags">
                  <ul>
                    <li>PRODUCT DESIGN</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1)</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
}

export default ProductDesignHouseholdDustbins

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    hddBanner: file(
      relativePath: { eq: "projects/project-banners/bg-project-pacman.jpg" }
    ) {
      ...fluidImage
    }
    outcome: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-13-outcome.jpg"
      }
    ) {
      ...fluidImage
    }
    senses: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-12-design-for-senses.jpg"
      }
    ) {
      ...fluidImage
    }
    kitchen: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-11-kitchen.jpg"
      }
    ) {
      ...fluidImage
    }
    usage: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-10-flexible-usage.jpg"
      }
    ) {
      ...fluidImage
    }
    oppurtunity: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-09-market-opportunity.jpg"
      }
    ) {
      ...fluidImage
    }
    dispenser2: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-08-dispensor-mechanism2.jpg"
      }
    ) {
      ...fluidImage
    }
    dispenser1: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-07-dispensor-mechanism1.jpg"
      }
    ) {
      ...fluidImage
    }
    howitworks: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-06-how-it-works.jpg"
      }
    ) {
      ...fluidImage
    }
    disInspiration: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-05-dispensor-inspiration.jpg"
      }
    ) {
      ...fluidImage
    }
    components: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-04-inner-components.jpg"
      }
    ) {
      ...fluidImage
    }
    sketch: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-03-sketch.jpg"
      }
    ) {
      ...fluidImage
    }
    inspiration: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-02-inspiration.jpg"
      }
    ) {
      ...fluidImage
    }
    research: file(
      relativePath: {
        eq: "projects/project-banners/blink-clyde-inky-pinky-01-research.jpg"
      }
    ) {
      ...fluidImage
    }
    project1: file(relativePath: { eq: "projects/12.png" }) {
      ...fluidImage
    }
    project2: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidImage
    }
  }
`
